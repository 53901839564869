import axios from 'axios';
import router from '../router';
import store from '../store';

/**
 * Add the current filialen-ID (if set) to the header of each request.
 */
axios.interceptors.request.use(request => {
  let aktiveFiliale = store.getters['fivehunters/aktiveFiliale'];
  if(aktiveFiliale) {
    request.headers['X-LANDWEHR-FILIALE'] = aktiveFiliale.id;
  }
  return request
});

/**
 * Handle access of protected endpoint with an unauthorized user.
 * IF
 *  - request is unauthorized, the status code is 401
 * AND
 *  - its not the basic request --> so we can show normal pages like the dashboard (should only be applied on protected endpoints)
 * THEN
 *  - redirect to login page
 */
axios.interceptors.response.use(response => response, error => {
  if (401 === error.response.status && !error.request.responseURL.endsWith('/api/basedata')) {
    router.push({'name' : 'login'});//TODO add parameter to add a redirect to the original page after the login
  } else {
    return Promise.reject(error);
  }
});