import store from './../../store'
import axios from 'axios'

//@see https://router.vuejs.org/guide/advanced/meta.html
export default async (to, from, next) => {
  if (to.matched.some(record => record.meta.resetAuth)) {
    //do full logout
    axios.post('/logout').then(response => {
      //DO NOT DISPATH resetState -> it leads to an endless loop
      //store.dispatch('fivehunters/resetState');
    });
    next();
  } else {
    next(); // make sure to always call next()!
  }
}