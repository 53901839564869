<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    
    <v-toolbar>
      <v-toolbar-items>
        <router-link :to="{ name: 'home' }" class="v-toolbar__title v-btn v-btn--flat text-none px-2">
          <img src="./../assets/images/logo.png" alt="digital.fivehunters.de" height="40" /> 
          <small class="ml-3 d-none d-sm-inline-block">digital.fivehunters.de</small>
        </router-link>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      
      <v-toolbar-items v-if="isLoggedIn">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed>
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="$router.push({ name: 'profile' })">
                <v-list-item-icon class="mr-3"><v-icon>mdi-account</v-icon></v-list-item-icon>
                <v-list-item-title class="pr-5">Profil</v-list-item-title>
              </v-list-item>
              <Logout>
                <template v-slot:activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-icon class="mr-3"><v-icon>mdi-power</v-icon></v-list-item-icon>
                    <v-list-item-title class="pr-5">Logout</v-list-item-title>
                  </v-list-item>
                </template>
              </Logout>
            </v-list>
          </v-menu>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import Logout from '@/components/baseSystem/Logout';
export default {
  components:{ Logout },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authtest/isLoggedIn'
    }),
  },
  methods: {
    userCan(permission) {
      return this.$store.getters['authtest/userCan'](permission);
    }
  }
}
</script>