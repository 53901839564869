import axios from 'axios';

/** mutationTypes ************************************************************************/
export const mutationTypes = {
};

let loadingBaseData = false;

// lets create the default state
const getInitialState = () => {
  return {
    basedata: null,//null = not loaded, false = not autorized
    aktiveFiliale: null
  }
}

/** store module **************************************************************************/
export const storeModule = {
  namespaced: true,
  state: getInitialState(),

  // getters => all the public available access points
  getters: {
    basedata: state => state.basedata,
    userId: state => {
      if(state.basedata && state.basedata.user) return state.basedata.user.id;
      return false;
    },
    isLoggedIn: state => !(state.basedata===null || state.basedata===false),
    aktiveFiliale: (state) => {
      if(!state.basedata || state.aktiveFiliale === null) return null;
      const result = state.basedata.user.filialen.find( filiale => filiale.id === state.aktiveFiliale );
      if(result===undefined) return null;
      return result;
    },
    userCan: (state) => (permission) => {
      if(state.basedata && state.basedata.permissions.indexOf(permission)>-1) return true;//permission found
      return false;
    }
  },

  // mutations => all the things that change the state of the store
  mutations: {
    setBasedata(state, { newValue }) {
      state.basedata = newValue;
    },
    setAktiveFiliale(state, { newValue} ) {
      state.aktiveFiliale = newValue;
      let userId = (state.basedata && state.basedata.user) ? state.basedata.user.id : 0;
      window.localStorage.setItem('aktiveFiliale_'+userId, newValue);
    },
    loadAktiveFiliale(state) {
      let userId = (state.basedata && state.basedata.user) ? state.basedata.user.id : 0;
      let aktiveFiliale = window.localStorage.getItem('aktiveFiliale_'+userId);
      if(aktiveFiliale!==null && aktiveFiliale.length>0) {
        aktiveFiliale = parseInt(aktiveFiliale,10);
      }
      state.aktiveFiliale = aktiveFiliale;
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },

  // actions => all the actions/functionality the store can perform
  actions: {
    /**
     * fetch the basedata from the API (customers and projects),
     * <code>
     * // fetch
     * this.$store.dispatch('fivehunters/basedata');
     * </code>
     */
    async basedata({ commit,dispatch }, params) {
      try {
        if(loadingBaseData) return;
        loadingBaseData = true;
        const { data } = await axios.get('/api/basedata', { params: { } });
        loadingBaseData = false;
        console.log('[basedata] loaded');
        commit('setBasedata', { newValue: data });
        commit('loadAktiveFiliale');
      } catch (e) {
        loadingBaseData = false;
        console.log('[basedata] not found',e);
        commit('setBasedata', { newValue: false });
        commit('loadAktiveFiliale');
      }
    },

    setAktiveFiliale({commit}, params) {
      commit('setAktiveFiliale', { newValue: params.id} );
    },

    resetState ({ commit,dispatch }) {
      commit('resetState');
      dispatch('basedata');
    },
  }
}