<template>
  <div class="createUserWrap">
    <v-form @submit.prevent="submit" v-if="$store.getters['fivehunters/userCan']('edit users')">
      <v-text-field filled v-model="form.name" label="Name" :error-messages="errors.name"></v-text-field>
      <v-text-field filled v-model="form.email" label="E-Mail" :error-messages="errors.email"></v-text-field>

      
      <div class="d-flex">
        <v-btn @click="$router.push({name:'admin_mitarbeiter'})" class="ml-1 my-1" depressed>Mitarbeiter-Verwaltung</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" type="submit" depressed color="primary">User anlegen</v-btn>
      </div>

      <p class="mt-10 text-center">
        Einem neuen User wird automatisch eine Passwort-Zurücksetzen E-Mail erzeugt, so dass der User innerhalb der nächsten 60 Minuten ein eigenes Passwort setzen kann.
      </p>
    </v-form>
    <div v-else>
      Diese Funktion steht ihnen nicht zur Verfügung
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  data: () => ({
    form:{
      name: '',
      email: '',
    },
    errors:[],
    loading:false
  }),
  methods:{
    submit() {
      let that = this;
      if(this.loading) return;
      this.loading = true;
      this.errors = [];
      axios({
        method: 'post',
        url: '/api/createuser',
        data: this.form
      }).then(response => {
          that.form = {
            name: '',
            email: '',
          };
          document.fivehunters_messages.openAlert({ title: "User wurde erstellt", message: 'Ein neuer User wurde erstellt.' });
          that.$emit('complete');
        })
        .catch(error => {
          that.errors = error.response.data.errors;
        })
        .then(()=> {
          that.loading = false;
        });
    },
  }
}
</script>