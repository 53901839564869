<template>
  <div>
    <h3>{{basedata.user.name}}</h3>
    <p>E-Mail: {{basedata.user.email}}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      basedata: 'fivehunters/basedata',
    }),
  }
}
</script>