<template>
  <v-form @submit.prevent="submit">
    <v-text-field filled v-model="form.name" label="Name" :error-messages="errors.name"></v-text-field>
    <v-text-field filled v-model="form.email" label="E-Mail" :error-messages="errors.email"></v-text-field>
    <v-text-field filled type="password" v-model="form.password" label="Passwort" :error-messages="errors.password"></v-text-field>
    <v-text-field filled type="password" v-model="form.password_confirmation" label="Passwort Bestätigung" :error-messages="errors.password_confirmation"></v-text-field>
    
    <v-slide-y-transition>
      <v-alert v-if="failError" class="my-2" type="error">{{failError}}</v-alert>
    </v-slide-y-transition>

    <div class="d-flex flex-wrap">
      <v-btn @click="$router.push({name:'login'})" class="mt-1" depressed>Sie sind bereits registriert?</v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-1" depressed color="primary">Registrieren</v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  data: () => ({
    form:{
      name:"",
      email:"",
      password:"",
      password_confirmation:"",
    },
    errors:[],
    failError:'',
  }),
  methods:{
    submit() {
      let that = this;
      this.errors = [];
      this.failError = '';
      axios.get('/sanctum/csrf-cookie').then(response => {//first get the csrf-cookie (will be added in any further requests)
        axios.post('/register', this.form)
          .then(response => {
            that.$store.dispatch('fivehunters/basedata');
            that.$emit('complete');
            that.$router.push({ name: "home" });
          })
          .catch(error => {
            console.log(error.response);
            if(error.response.status === 405) {
              that.failError = 'Die Neuregistrierung am System wurde deaktiviert.';
            } else {
              that.errors = error.response.data.errors
            }
          }); // credentials didn't match
      });
    },
  }
}
</script>