<template>
  <v-footer padless fixed dark>
    <v-card class="flex" flat tile>
      <v-card-text class="py-2 white--text text-center">
        <app-version></app-version>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import AppVersion from '@/components/AppVersion.vue';
export default {
  components: {
    AppVersion,
  },
}
</script>