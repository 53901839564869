<template>
  <div class="hello">
    <OAuthLogin></OAuthLogin>
    <v-divider></v-divider>
    <v-alert type="success" v-if="isLoggedIn">Ist eingeloggt</v-alert>
    <v-alert type="error" v-if="!isLoggedIn">Ist nicht eingeloggt</v-alert>
    <v-divider></v-divider>
    <Logout></Logout>
  </div>
</template>

<script>
import Login from '@/components/baseSystem/Login.vue';
import Logout from '@/components/baseSystem/Logout.vue';
import ClearCache from '@/components/administration/ClearCache.vue';
import ChangePassword from '@/components/baseSystem/ChangePassword.vue';
import OAuthLogin from '@/components/baseSystem/OAuthLogin.vue';

import { mapGetters } from 'vuex';
export default {
  components:{ Login, ClearCache, ChangePassword, OAuthLogin, Logout },
  props: {},
  data: () => ({
    updateUserPassword:false,
    debugView:false,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: 'fivehunters/isLoggedIn',
    }),
  },
  mounted() {
    this.debugView = 'true' === window.localStorage.getItem('fivehunters_debug_view');
  },
  methods:{
    showTodo() {
      document.fivehunters_messages.openAlert({ title:'TODO', message:'Anbindung noch offen'})
    },
    updateDebugView(value) {
      window.localStorage.setItem('fivehunters_debug_view',value);
    }
  }
}
</script>