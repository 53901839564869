<template>
    <div>
        <h1>OAuth Login</h1>
        <ProfileInfo></ProfileInfo>
        <v-alert type="success" v-if="isLoggedIn">Ist eingeloggt</v-alert>
        <v-alert type="error" v-if="!isLoggedIn">Ist nicht eingeloggt</v-alert>
        
        <router-link :to="{ name: 'home' }">Link to home</router-link>
    </div>
</template>

<script>
import ProfileInfo from "@/components/baseSystem/ProfileInfo.vue"
import { mapGetters } from 'vuex';
export default {
    components: {ProfileInfo},
    computed: {
    ...mapGetters({
            isLoggedIn: 'fivehunters/isLoggedIn',
        }),
    },
    mounted() {
        console.log('oauth login success')
        if(this.$store.getters['fivehunters/isLoggedIn']) {
            alert('ist eingeloggt');
            this.$router.push({name: 'home'});
        }
        /*
        this.$store.dispatch('fivehunters/resetState').then(() => {
            console.log('oauth check isLoggedIn', this.$store.getters['fivehunters/isLoggedIn']);
        });*/
    }
}
</script>