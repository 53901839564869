<template>
  <div class="resetPasswordWrap">
    <v-card max-width="600px" class="mx-auto mt-10">
      <v-card-text>
        <ResetPassword :token="token"></ResetPassword>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResetPassword from "@/components/baseSystem/ResetPassword.vue"
export default {
  components: { ResetPassword },
  props: { 
    token: {
      required:false
    }
  }
}
</script>
