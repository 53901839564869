<template>
    <div>
        <a href="/oauth/google-redirect">Authetifizieren mit Google Auth2 (neu)</a>
    </div>
</template>

<script>
export default {
}
</script>
