<template>
  <div class="forgotPasswordWrap">
    <v-card max-width="600px" class="mx-auto mt-10">
      <v-card-text>
        <ForgotPassword></ForgotPassword>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ForgotPassword from "@/components/baseSystem/ForgotPassword.vue"
export default {
  components:{ ForgotPassword }
}
</script>
