<template>
  <div class="resetPasswordWrap">
    <v-card max-width="600" class="mx-auto mt-10">
      <v-card-title>Profil-Infos</v-card-title>
      <v-card-text>
        <ProfileInfo></ProfileInfo>
      </v-card-text>
    </v-card>
    <v-card max-width="600" class="mx-auto mt-10">
      <v-card-title>Passwort ändern</v-card-title>
      <v-card-text>
        <ChangePassword @complete="passwordChanged=true" v-if="!passwordChanged"></ChangePassword>
        <v-alert v-else type="success">Passwort wurde geändert.</v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ChangePassword from "@/components/baseSystem/ChangePassword.vue"
import ProfileInfo from "@/components/baseSystem/ProfileInfo.vue"
export default {
  components: { ChangePassword, ProfileInfo },
  props: { 
    token: {
      required:false
    }
  },
  data:() =>({
    passwordChanged:false
  })
}
</script>
