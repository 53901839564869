//Base System

export default [
  { 
    path: '/', 
    name: 'home', 
    component: require('../pages/Home.vue').default,
    meta: { requiresAuth: false }
  }, { 
    path: '/oauth-login-success', 
    name: 'oauth-login-success', 
    component: require('../pages/OAuthLoginSuccess.vue').default, 
    meta: { requiresAuth: false, }
  }, { 
    path: '/oauth-login-failed', 
    name: 'oauth-login-failed', 
    component: require('../pages/OAuthLoginFailed.vue').default, 
    meta: { requiresAuth: false, }
  }, { 
    path: '/dologout', 
    name: 'logout', 
    component: require('../pages/base/Logout.vue').default, 
    meta: { requiresAuth: false, }
  }, { 
    path: '/profile', 
    name: 'profile', 
    component: require('../pages/base/Profile.vue').default, 
    meta: { requiresAuth: true }
  }, { 
    path: '/createuser', 
    name: 'createuser', 
    component: require('../pages/base/CreateUser.vue').default, 
    meta: { requiresAuth: true }
  }, {
    path: '/base/login', 
    name: 'login', 
    component: require('../pages/base/Login.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/register', 
    name: 'register', 
    component: require('../pages/base/Register.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/forgot-password', 
    name: 'forgot-password', 
    component: require('../pages/base/ForgotPassword.vue').default, 
    meta: { requiresAuth: false, resetAuth: true }
  }, { 
    path: '/base/reset-password/:token', 
    name: 'reset-password', 
    component: require('../pages/base/ResetPassword.vue').default, props: true,
    meta: { requiresAuth: false, resetAuth: true }
  },
  //TODO: 'base/user/confirm-password'
  //TODO: 'base/user/confirmed-password-status'

  //TODO: 'base/email/verify'

  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/About.vue')
    meta: { requiresAuth: true }
  }
  //*/
  { path: '*', component: require('../pages/404.vue').default, meta: { bodyClass: 'page404' }, }
];
