
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const mutationTypes = {};
const storeObj = {
    modules: {}//fill modules later
};


// Load lang module
//const i18n = require('../i18n/store.lang.js');
//storeObj.modules.lang = i18n.storeModule;
//Object.assign(mutationTypes, i18n.mutationTypes);

// Load module
const fivehunters = require('./store.authtest.js');
storeObj.modules.fivehunters = fivehunters.storeModule;
Object.assign(mutationTypes, fivehunters.mutationTypes);


const store = new Vuex.Store(storeObj);
export default store;
