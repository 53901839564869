import store from './../../store'

//@see https://router.vuejs.org/guide/advanced/meta.html
export default async (to, from, next) => {
  if(store.getters['fivehunters/basedata']===null) {
    await store.dispatch('fivehunters/basedata');//make sure the basedata are loaded
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['fivehunters/isLoggedIn']) {
      // redirect to login
      next({ name: 'login', query: { redirect: to.fullPath } });//attache the current target path, to be able to redirect after login (if needed)
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
}
