<template>
  <div id="app">
    <v-app>
      <v-main>
        <app-navigation></app-navigation>
        <v-container class="mainContainer">
          <OverlayLoading :show="!authRequestComplete" color="primary">loading</OverlayLoading>
          <router-view v-if="authRequestComplete"></router-view>
        </v-container>
        <app-footer></app-footer>
        <MessageConfirm entrypoint="fivehunters_messages"></MessageConfirm>
        <MessageAlert entrypoint="fivehunters_messages"></MessageAlert>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import appNavigation from '@/components/AppNavigation.vue';
import appFooter from '@/components/AppFooter.vue';
import MessageConfirm from '@/components/MessageConfirm.vue';
import MessageAlert from '@/components/MessageAlert.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';

export default {
  components:{
    appNavigation,
    appFooter,
    MessageConfirm,
    MessageAlert,
    OverlayLoading
  },
  computed: {
    authRequestComplete() {
      return this.$store.getters['fivehunters/basedata']!==null;
    }
  },
  mounted() {
    //! Dont need the following line - is done in router/middleware/auth !
    //this.$store.dispatch('fivehunters/basedata');
  }
}
</script>