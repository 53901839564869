import Vue from 'vue'
import { de } from 'date-fns/locale'
import { format, parse, parseISO } from 'date-fns'
import store from './store'
import Debug from './components/Debug.vue';

const locales = { de };
const i18n = {
  locale: de
};

//global components
Vue.component('debug', Debug);


let MyGlobal = {
  install(Vue, options) {

    // add an instance method
    Vue.prototype.$helper = {
      test() {
        return 'läuft';
      },

      format(date, formatStr = 'PP') {
        return format(date, formatStr, {
          locale: locales[ i18n.locale ] // or global.__localeId__
        })
      },

      formatDatePicker_title(value) {
        return value ? format( parse(value,'yyyy-MM-dd', new Date(2000, 1, 1)), 'dd. MMM yyyy', { locale: locales[ i18n.locale ] } ) : '';
      },
      formatDatePicker_header(value) {
        if(value.length===4) return value;
        return value ? format( parse(value,'yyyy-MM', new Date(2000, 1, 1)), 'MMM yyyy', { locale: locales[ i18n.locale ] } ) : '';
      },
      formatDatePicker_month(value) {
        return value ? format( parse(value,'yyyy-MM', new Date(2000, 1, 1)), 'MMMM', { locale: locales[ i18n.locale ] } ) : '';
      },
      formatDatePicker_weekday(value) {
        return value ? format( parse(value,'yyyy-MM-dd', new Date(2000, 1, 1)), 'EEE', { locale: locales[ i18n.locale ] } ) : '';
      },

      formatDateLandwehr(value, formatStr) {
        if(formatStr === undefined) formatStr = "dd.MM.yyyy HH:mm";
        return value ? format( parseISO(value), formatStr, { locale: locales[ i18n.locale ] } ) : '';
      },

      aktiveFiliale() {
        return store.getters['fivehunters/aktiveFiliale'];
      },


      getArrayFromObject(obj) {
        if(obj) {
          let result = Object.keys(obj).map(function(key) {
            return obj[key];
          });
          return result;
        }
        return [];
      },

      /**
       * Transforms a php date string into a js date object.
       * @param {string} phpTimestamp 
       * @return {Date|NULL} The resulting Date-object or NULL
       */
      transformPhpTimestamp:function(phpTimestamp) {
        if(typeof phpTimestamp === 'string' && phpTimestamp.length>0) {
          return new Date(parseInt(phpTimestamp)*1000);//simple -> exact by the second
        }
        return null;
      },
      
      /**
       * Transforms a ISO-date string into a PHP timestamp
       * @param {Date} dateObject a javascript Date object 
       * @returns {null|int} returns a php timestamp (null if isoDateString is NULL)
       */
      getPhpTimestamp: function (dateObject) {
        if(dateObject && typeof dateObject==="object" && dateObject.getTime) {
          return parseInt(dateObject.getTime() / 1000);
        }
        return null;
      },
    }
  }
}
Vue.use(MyGlobal);