import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'


Vue.use(VueRouter);




// create router
const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV==='production' ? '/' : process.env.BASE_URL,
  routes
});

/*** SETUP MIDDLEWARE */
// The middleware for every page of the application.
const globalMiddleware = ['auth','reset-auth'];

// Load middleware files dynamically.
const routeMiddleware = resolveMiddleware( require.context('./middleware', false, /.*\.js$/) );

// Define before and after
router.beforeEach(beforeEachRoute);
router.afterEach(afterEachRoute);
/*** END MIDDLEWARE */

export default router

/*** functions *********************************************************************************/


/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEachRoute(to, from, next) {
    // Get the matched components and resolve them.
    const components = await resolveComponents(
        router.getMatchedComponents({ ...to })
    );

    if (components.length === 0) {
        return next();
    }

    /*
    // Start the loading bar.
    if (components[components.length - 1].loading !== false) {
        router.app.$nextTick(() => router.app.$loading.start())
    }
    */

    // Get the middleware for all the matched components.
    const middleware = getMiddleware(components)

    // Call each middleware.
    callMiddleware(middleware, to, from, (...args) => {
        // Set the application layout only if "next()" was called with no args.
        /*
        if (args.length === 0) {
            router.app.setLayout(components[0].layout || '')
        }*/

        next(...args);
    })
}



/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEachRoute(to, from, next) {
    await router.app.$nextTick()

    //router.app.$loading.finish()
}


/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware(middleware, to, from, next) {
    const stack = middleware.reverse()

    const _next = (...args) => {
        // Stop if "_next" was called with an argument or the stack is empty.
        if (args.length > 0 || stack.length === 0) {
            if (args.length > 0) {
                //router.app.$loading.finish()
            }

            return next(...args)
        }

        const middleware = stack.pop()

        if (typeof middleware === 'function') {
            middleware(to, from, _next)
        } else if (routeMiddleware[middleware]) {
            routeMiddleware[middleware](to, from, _next)
        } else {
            throw Error(`Undefined middleware [${middleware}]`)
        }
    }

    _next()
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware(components) {
    const middleware = [...globalMiddleware]

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware)) {
            middleware.push(...component.middleware)
        } else {
            middleware.push(component.middleware)
        }
    })

    return middleware
}


/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
    return requireContext.keys()
        .map(file =>
            [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
        )
        .reduce((guards, [name, guard]) => (
            { ...guards, [name]: guard.default }
        ), {})
}