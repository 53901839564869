<template>
  <v-form @submit.prevent="submit">
    <v-text-field filled v-model="form.email" name="email" label="E-Mail" :error-messages="errors.email"></v-text-field>
    <v-text-field filled v-model="form.password" name="password" type="password" label="Passwort" :error-messages="errors.password"></v-text-field>
    
    <div class="d-flex flex-wrap">
      <v-btn @click="$router.push({name:'register'})" class="mt-1 mr-1" depressed>Noch nicht registriert?</v-btn>
      <v-btn @click="$router.push({name:'forgot-password'})" class="mt-1" depressed>Passwort vergessen?</v-btn>
      <v-spacer></v-spacer>
      <v-btn type="submit" class="mt-1" depressed color="primary">Login</v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    msg: String
  },
  data: () => ({
    form:{
      email:"",
      password:"",
    },
    errors:[],
  }),
  methods:{
    submit() {
      let that = this;
      axios.get('/sanctum/csrf-cookie').then(response => {//first get the csrf-cookie (will be added in any further requests)
        axios.post('/login', this.form)
          .then(response => {
            that.$store.dispatch('fivehunters/basedata');
            that.$emit('complete');
          })
          .catch(error => {
            console.log('error',error.response);
            that.errors = error.response.data.errors
          }); // credentials didn't match
      });
    },
  }
}
</script>